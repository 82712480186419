html,
body,
.plug-play-page h1,
.plug-play-page h2,
.plug-play-page h3,
.plug-play-page h4,
.plug-play-page h5,
.plug-play-page h6,
.plug-play-page ul li,
.plug-play-page .default-btn,
.plug-play-page div {
  font-family: "Noto Sans", sans-serif !important;
  font-optical-sizing: auto;
}

.plug-play-page p {
  font-size: 21px;
  color: #fff;
  line-height: 31px;
  font-weight: 400;
}

/*----------------------
plug and play
--------------------*/
.plug-play-page {
  background-color: #13303c;
}

.plug-play-page .header_area {
  padding-top: 20px;
  position: relative;
  z-index: 111;
}

.hero-padding {
  padding: 60px 0 0;
}

.hero-gradient-wrapper {
  position: absolute;
  left: 135px;
  top: -100px;
  width: 500px;
  height: 500px;
  background: radial-gradient(circle at 50% 50%, #00d0ff, rgba(76, 0, 255, 0));
  opacity: 0.5;
}

.main-cont {
  border-radius: 20px;
  padding: 20px 35px;
  background: #14303c;
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.main-btn {
  padding: 10px 18px;
  background-color: #ffbf00;
  border-radius: 6px;
  color: #000;
  font-weight: 600;
  border: 2px solid #ffbf00;
  width: fit-content;
  display: block;
  font-size: 21px;
  display: flex;
  align-items: center;
}

.main-btn:hover {
  color: #000 !important;
}

.main-btn i {
  margin-left: 10px;
}

.dark-grad,
.slide_area,
.time-line-sec {
  background-color: #000;
}

.service_card {
  width: auto;
  max-width: 100%;
  max-height: 100%;
  margin-bottom: 0;
  padding: 0;
  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
  border-radius: 0;
}

.service_card,
.service_img {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.service_card {
  background-color: #214453;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);

  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  box-shadow: none;
}

.service_img {
  padding: 25px;
  background: 0 0;
}

.service_img::before {
  background-image: url(../../../app/assets/images/plug-and-play/pattern_service.png);
  background-repeat: repeat;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  z-index: -1;
}

#animationWindow,
.service_img::before {
  width: 100%;
  height: 100%;
}

.service_img img {
  width: 130px;
}

.service_card:hover .service_img img {
  transform: translateY(-7px);
  transition: 0.5s;
}

.service_img img {
  display: inline-block;
  transform: translateY(4px);
  margin-bottom: 0;
}

.service_content {
  padding: 40px 25px !important;
  z-index: 100;
  box-shadow: none;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  background-color: #214453;
  min-height: calc(100% - 181px);
}

.service_title {
  font-size: 22px;
  margin-bottom: 15px;
  line-height: 1.3;
}

.service_content .light_color {
  color: #dfdfdf;
  font-size: 18px;
}

.service_content h5 {
  color: #fff;
}

.service_content ul {
  margin-left: 20px;
  margin-top: 16px;
  list-style-type: disc;
}

.service_content ul li {
  color: #fff;
  font-size: 18px;
  margin-bottom: 10px;
}

.radius_btn,
.service_area,
.service_card,
.service_content,
.subheading {
  position: relative;
}
.counter-sec {
  margin-bottom: 50px;
}

.why-sec-count {
  margin-top: 40px;
  display: flex;
}

.why-sec-count h3 {
  color: #fff;
  font-weight: 500;
  font-size: 32px;
}

.why-sec-count h4 {
  color: #b9b9b9;
  font-weight: 500;
  font-size: 20px;
}

.cust-sticky {
  top: 0;
  padding: 50px 0 0;
}

aside {
  float: left;
  width: 45%;
}
.end-cta-sec p {
  color: #fff;
  padding: 0 100px;
}

.logo img {
  max-width: 200px;
}

.copyright_menu ul li a {
  text-transform: capitalize;
  font-size: 14px;
}

.copyright_menu ul li::before {
  position: absolute;
  right: -12px;
  height: 100%;
  width: 1px;
  background-color: #5a5a5a;
  content: "";
}

.copyright_menu ul li::before {
  position: absolute;
  right: -12px;
  height: 100%;
  width: 2px;
  background-color: #dbdbdb;
  content: "";
}

.copyright_text p {
  font-size: 14px !important;
}

.social-icon-custm a {
  color: #fff;
}

@media (max-width: 1920px) {
  img.imgPerson {
    left: -43.9%;
    top: -4px;
  }

  img.imgPerson2 {
    right: -44%;
  }
}

@media (max-width: 991px) {
  .hero-img-mob {
    width: 100%;
  }
}
.plug-play-page
  .react-slideshow-container
  + ul.indicators
  .each-slideshow-indicator:before {
  background: #fff !important;
  width: 10px;
  height: 10px;
}

.plug-play-page .react-slideshow-container + ul.indicators li {
  padding: 7px;
  margin-top: 10px;
}

.Marquee {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 1em;
  color: #fff;
  font-weight: 200;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  overflow: hidden;
}
.Marquee-content {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation: marquee 50s linear infinite running;
  -moz-animation: marquee 50s linear infinite running;
  -o-animation: marquee 50s linear infinite running;
  -ms-animation: marquee 50s linear infinite running;
  animation: marquee 50s linear infinite running;
}
.Marquee-content:hover {
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  -ms-animation-play-state: paused;
  animation-play-state: paused;
}
.Marquee-tag {
  width: 200px;
  margin: 0 1.5em;
  padding: 0.5em;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.Marquee-tag:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  cursor: pointer;
}
@-moz-keyframes marquee {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translate(-50%);
    -moz-transform: translate(-50%);
    -o-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }
}
@-webkit-keyframes marquee {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translate(-50%);
    -moz-transform: translate(-50%);
    -o-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }
}
@-o-keyframes marquee {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translate(-50%);
    -moz-transform: translate(-50%);
    -o-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }
}
@keyframes marquee {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translate(-50%);
    -moz-transform: translate(-50%);
    -o-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }
}

.wu-serv:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

/* Updated Custom CSS */
.cust-padding {
  padding: 100px 0 0;
}
.cust-padding-2 {
  padding: 100px 0;
}
.sec-primary-ic {
  background: #0c5b79;
  box-shadow: 0 28px 44px rgba(19, 68, 90, 0.35);
}
.main-btn {
  align-items: center;
  background-color: #ffbf00;
  border: 2px solid #ffbf00;
  border-radius: 6px;
  color: #000;
  display: block;
  display: flex;
  font-size: 21px;
  font-weight: 600;
  padding: 10px 18px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-decoration: none;
}
.plug-margin-btm {
  margin-bottom: 40px;
}
.bg-black,
.spin-wheel-modal {
  background-color: #13303c !important;
}
.cust-hero-card p {
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
}
.certfi-num {
  color: #fff;
  border-left: 1px solid #fff;
}
.cap-link {
  text-decoration: none;
}
#header-main {
  bottom: 100px;
  cursor: pointer;
  position: fixed;
  right: 0;
}
.back-top {
  background: #52bdc7;
  border-radius: 5px 0 0 5px;
  color: #000;
  display: none;
  font-size: 18px;
  font-weight: 500;
  height: 36px;
  line-height: 36px;
  padding: 0 12px;
  transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  width: 36px;
  z-index: 10;
}
.main-side-content {
  float: right;
  width: 50%;
  padding: 50px 0 0;
}
.social-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
ul {
  padding: 0;
}
.plug-play-wrapper {
  display: none;
}
.header_area {
  padding-top: 12px;
}

/* Thank You page CSS */
.thankyou-page-wrapper {
  height: 100%;
  min-height: calc(100vh - 177px);
}
.bg-light-green {
  background-color: #52bdc7;
}
.home-cta {
  border-radius: 20px;
  padding: 60px;
}
.home-cta img {
  margin-top: -134px;
  border-radius: 20px;
  box-shadow: rgb(0 0 0 / 16%) 0 10px 22px;
}
.inner {
  padding: 150px 0 80px;
}
.bg-light {
  background-color: #f5f5f5;
}
.sub-content {
  font-weight: 400;
}
.error-msg {
  position: absolute;
  font-size: 13px;
}
.cust-btn-primary {
  background-color: #0c5b79 !important;
  color: #fff !important;
}

.scroll-up a {
  position: fixed;
  bottom: 10%;
  right: 10px;
  background: #52bdc7;
  width: 2em;
  height: 2em;
  border-radius: 0.25rem 0rem 0rem 0.25rem;
  text-align: center;
  line-height: 2.2;
  color: #1c1c1c;
  z-index: 99;
  right: 0;
}

.scroll-up a i {
  font-size: 1.2em;
}

.count-margin {
  min-width: 174px;
}
.grecaptcha-badge {
  visibility: hidden !important;
}
